import '@livekit/react-components/dist/index.css';
import React from 'react';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import { PreJoinPage, Loader as RoomLoader } from './PreJoinPage';

import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Link } from "react-router-dom";


const App = ({ signOut, user }: any) => {
  console.log("user", user);

  const router = createHashRouter([{
      path: '/',
      element: <div>Are you a <Link to={"director"}>director</Link> or driver?</div>,
      },{
        path: '/director',
        element: <PreJoinPage showChannels={true} />},
        {
                  path: 'room/:roomName',
                  loader: RoomLoader,
                  element: <PreJoinPage showChannels={false} />,
      },
      { path: 'test', element: <div>test</div>}
  ]);

  return (

    <>

      <React.StrictMode>


        <div className="bg-white">
          <div className="xl:container xl:mx-auto">

              <div className="px-5 py-5 bg-gradient-to-bl from-sky-700 to-sky-900">
              <nav className="flex items-center justify-between" aria-label="Global">
                <div className="lg:flex-1 flex" aria-label="Global">
                  <div className='float'>
                    <h1 className="-mt-2 text-5xl subpixel-antialiased font-header text-transparent bg-clip-text bg-gradient-to-tl from-sky-300 via-cyan-200 to-sky-100">Radio</h1>
                  </div>
                </div>
                <div className="text-gray-200"></div>
                <div className="justify-end text-gray-100">
                  <div>Hello {user.username}</div>
                  <div className="">
                  <button className="text-xs"onClick={signOut}>Sign out</button>
                  </div>
                </div>
              </nav>
            </div>

          <main>
            <div className="mt-4">
              <RouterProvider router={router} />
            </div>
          </main>
        </div>
        </div>

      </React.StrictMode>

    </>
  );
};

export default withAuthenticator(App);
//export default App;
