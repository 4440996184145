import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataPacket_Kind, Participant, Room, RoomEvent, setLogLevel } from 'livekit-client';
import { DisplayContext, DisplayOptions, LiveKitRoom, ControlsView, ControlsProps, ParticipantProps } from '@livekit/react-components';
import { AudioParticipantView } from './Components/AudioParticipant/AudioParticipantView';
import { useEffect, useState } from 'react';
import 'react-aspect-ratio/aspect-ratio.css';
import { useLocation } from 'react-router-dom';
import { LocationRetriever } from './Components/LocationRetriever';
import QRCode from "react-qr-code";

import EventEmitter from './utils/EventEmitter';
export const AudioRoom = (params: any) => {

  const [talkingStyle, setTalkingStyle] = useState<string>("shadow-lg m-4 p-2");
  const [talking, setTalking] = useState<boolean>(false);
  const [talkingButtonDisabled, setTalkingButtonDisabled] = useState<boolean>(false);
  const [numParticipants, setNumParticipants] = useState(0);
  const [displayOptions, /*setDisplayOptions*/] = useState<DisplayOptions>({
    stageLayout: 'grid',
    showStats: true,

  });
  const [room, setRoom] = useState<Room>();
  const query = new URLSearchParams(useLocation().search);
  const recorder = query.get('recorder');

  const url = params.url;
  const token = params.token;
  const carNumber = params.carNumber || "00"
  if (!url || !token) {
    return <div>Url and Token are required</div>;
  }




  const toggleTalk = () => {

    if (room == null)
      return;

    if (talkingButtonDisabled) {
      return;
    }

    setTalkingButtonDisabled(true);
    setTalking(!talking);
    if (talking) {
      setTalkingStyle("shadow-lg m-4 p-2 bg-green-100");
    }
    else {
      setTalkingStyle("shadow-lg m-4 p-2");
    }
    room.localParticipant
      .setMicrophoneEnabled(talking)
      .finally(() => setTalkingButtonDisabled(false));

  }


  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    let listenerFunction = (payload: Uint8Array, participant?: Participant, kind?: DataPacket_Kind) => {

      const decoder = new TextDecoder()
      const data = JSON.parse(decoder.decode(payload));

    //  if (data.type === "flag"){
    //    console.log("Received Flag", data);
    //    return;
    //  }
      let location = data;

      console.log("Kind", kind);
      if (participant) {
        EventEmitter.emit("locationReceived", {location, participant});
    }
    };
    if (room) {
      room.on(RoomEvent.DataReceived, listenerFunction);
     // EventEmitter.addListener("flagPressed", (payload: any) => {
     //   room.localParticipant.publishData(payload, DataPacket_Kind.RELIABLE)
    //  }
    //    );

      return () => { room.removeListener(RoomEvent.DataReceived, listenerFunction) }


    }


    return () => { };
  }, [room]);

  const onLeave = () => {

  };

  const updateParticipantSize = (room: Room) => {
    setNumParticipants(room.participants.size + 1);

  };

  const onParticipantDisconnected = (room: Room) => {
    updateParticipantSize(room);

    /* Special rule for recorder */
    if (recorder && parseInt(recorder, 10) === 1 && room.participants.size === 0) {
      console.log('END_RECORDING');
    }
  };

  const onLocationChanged = (location: any) => {
    let packet = { lat: location.coords.latitude, long: location.coords.longitude, timestamp: location.coords.timestamp, speed: location.coords.speed }
    const encoder = new TextEncoder()
    const data = encoder.encode(JSON.stringify(packet));
    if (room != null && room.localParticipant != null) {
      room.localParticipant.publishData(data, DataPacket_Kind.RELIABLE)
      //Publish our own position.
      EventEmitter.emit("locationReceived", {type:'location', location, participant: room.localParticipant})
    }
  }


  let roomName = "Connecting to room";
  let locationComponent: any = "";
  if (room) {
    roomName = room.name;
    locationComponent = (<LocationRetriever onLocationChanged={onLocationChanged}></LocationRetriever>)

  }
  let link = "https://www.radiodev.headsup.team/#/room/" + roomName;
  return (
    <>
      <div className={talkingStyle} onClick={() => toggleTalk()}>
        <div className="" >
          <DisplayContext.Provider value={displayOptions}>
            <div className="flex flex-row justify-between pt-2 pr-2">
              <div className="text-2xl"><span>{carNumber}</span></div>
              <div>
              <span className="text-gray-800">
                  <FontAwesomeIcon icon={faUserFriends} className="pr-1" />{numParticipants}</span>

                <div className='lg:visible collapse' style={{ height: "auto", margin: "0 auto", maxWidth: 64, width: "100%" }}>
                  <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={link}
                    viewBox={`0 0 256 256`}
                  />
                </div>

              </div>
            </div>

            <div className='flex justify-between'>

            </div>
            <LiveKitRoom
              url={url}
              token={token}
              onConnected={(room) => {
                setLogLevel('debug');
                setRoom(room);
                onConnected(room, query);
                room.on(RoomEvent.ParticipantConnected, () => updateParticipantSize(room));
                room.on(RoomEvent.ParticipantDisconnected, () => onParticipantDisconnected(room));
                updateParticipantSize(room);
              }}

              participantRenderer={(props: ParticipantProps) => {
                return <AudioParticipantView participant={props.participant}
                  className={"customParticipantView"}
                  speakerClassName={"customSpeakerView"}
                  width={'180px'}
                  height={'210px'}></AudioParticipantView>;
              }}

              controlRenderer={(props: ControlsProps) => {
                return <div className='flex justify-end'><ControlsView room={props.room} enableVideo={false} enableScreenShare={false}></ControlsView></div>;
              }}
              onLeave={onLeave}
            />

{locationComponent}
          </DisplayContext.Provider >
        </div>
      </div>
    </>
  );
};

async function onConnected(room: Room, query: URLSearchParams) {
  // make it easier to debug
  (window as any).currentRoom = room;

  if (isSet(query, 'audioEnabled')) {
    const audioDeviceId = query.get('audioDeviceId');
    if (audioDeviceId && room.options.audioCaptureDefaults) {
      room.options.audioCaptureDefaults.deviceId = audioDeviceId;
    }
    await room.localParticipant.setMicrophoneEnabled(true);

  }

}

function isSet(query: URLSearchParams, key: string): boolean {
  return query.get(key) === '1' || query.get(key) === 'true';
}
