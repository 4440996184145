import  {
  ReactElement,

  useEffect,
  useState,
} from 'react';

import { faMicrophone, faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Property } from 'csstype';
import { ConnectionQuality, LocalTrack, Participant, RemoteTrack } from 'livekit-client';
import { useParticipant } from '@livekit/react-core';


import { ReactComponent as connectionQuality1 } from '../static/connection-quality-1.svg';
import { ReactComponent as connectionQuality2 } from '../static/connection-quality-2.svg';
import { ReactComponent as connectionQuality3 } from '../static/connection-quality-3.svg';





import { Image } from '@aws-amplify/ui-react';

var md5Hash = require("md5-hash").default;

export interface ParticipantProps {
  participant: Participant;
  displayName?: string;
  // width in CSS
  width?: Property.Width;
  // height in CSS
  height?: Property.Height;
  className?: string;
  // aspect ratio width, if set, maintains aspect ratio
  aspectWidth?: number;
  // aspect ratio height
  aspectHeight?: number;
  // determine whether to contain or cover video.
  // cover mode is used when layout orientation matches video orientation
  orientation?: 'landscape' | 'portrait';
  // true if overlay with participant info should be shown
  showOverlay?: boolean;
  // true if connection quality should be shown
  showConnectionQuality?: boolean;
  // additional classname when participant is currently speaking
  speakerClassName?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
}

export const AudioParticipantView = ({
  participant,

  displayName,
  showConnectionQuality,
  onMouseEnter,
  onMouseLeave,
  onClick,
}: ParticipantProps) => {
  const { isLocal, connectionQuality, isSpeaking } = useParticipant(participant);
  const [currentBitrate, setCurrentBitrate] = useState<number>();


  useEffect(() => {
    const interval = setInterval(() => {
      let total = 0;
      participant.tracks.forEach((pub) => {
        if (pub.track instanceof LocalTrack || pub.track instanceof RemoteTrack) {
          total += pub.track.currentBitrate;
        }
      });
      setCurrentBitrate(total);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);



  if (!displayName) {
    displayName = participant.name || participant.identity;
    if (isLocal) {
      displayName += ' (You)';
    }
  }

<div className="" />
let speakingStyle = "";
let speakingContent: ReactElement | undefined;
  if (isSpeaking) {
    speakingContent = (<div>Speaking</div>);
    speakingStyle="text-green-800";
  }
  const isAudioMuted = !participant.isMicrophoneEnabled;

  // gather stats
  let statsContent: ReactElement | undefined;

    statsContent = (
      <div className="">
        {currentBitrate !== undefined && currentBitrate > 0 && (
          <span>&nbsp;{Math.round(currentBitrate / 1024)} kbps</span>
        )}
      </div>
    );


  let ConnectionQualityIndicator: typeof connectionQuality1 | undefined;
  if (showConnectionQuality) {
    switch (connectionQuality) {
      case ConnectionQuality.Excellent:
        ConnectionQualityIndicator = connectionQuality3;
        break;
      case ConnectionQuality.Good:
        ConnectionQualityIndicator = connectionQuality2;
        break;
      case ConnectionQuality.Poor:
        ConnectionQualityIndicator = connectionQuality1;
        break;
    }
  }
  let email = "";
  let avatarImage = "https://www.gravatar.com/avatar/"
  if (participant.metadata) {
    email = JSON.parse(participant.metadata).email;

    avatarImage = avatarImage + md5Hash(email.trim().toLowerCase()).toString();
  }
  avatarImage += "?s=60&d=mp"
  console.log("avatarImage:", avatarImage)

  if(isLocal) {
    // return <></>
  }

  return (
    <div
      className="flex"

      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      <div className="">
        <Image src={avatarImage} className={`rounded-full z-0 m-2 ring-4 ${isAudioMuted ? 'ring-transparent' : 'ring-green-800'}`}  alt="gravatar"/>
      </div>
        <div className="hidden">
          <div className=""></div>
          <div className=""></div>
          <div>{ConnectionQualityIndicator && <ConnectionQualityIndicator />}</div>
          <div>
            <FontAwesomeIcon
              icon={isAudioMuted ? faMicrophoneSlash : faMicrophone}
              height={24}
              className={speakingStyle}
            />
            {speakingContent}
            {statsContent}
          </div>
        </div>

    </div>
  );
};
