import { AudioSelectButton } from '@livekit/react-components';
import { useEffect, useState } from 'react';

import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { AudioRoom } from './AudioRoom';
import { Map } from './Components/Map';
import { useLoaderData } from "react-router-dom";
//import EventEmitter from './utils/EventEmitter';
//import { connect } from 'http2';
interface PreJoinProps {
  showChannels: boolean;
}

export const Loader = ({ params }: any): string => {
  console.log("Room Loader:", params);
  return params.roomName;
}


export const PreJoinPage = (props: PreJoinProps) => {
  const roomLoader = useLoaderData();

  // initial state from query parameters

  const storedUrl = 'wss://radio.headsup.team';

  const [joinedRooms, setJoinedRooms] = useState<string[]>([]);

  // state to pass onto room

  const [email, setEmail] = useState("");

  const [username, setUsername] = useState("");

  const [audioEnabled, setAudioEnabled] = useState(true);

  const [audioDevice, setAudioDevice] = useState<MediaDeviceInfo>();


  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [showChannels] = useState<boolean>(props.showChannels);

  const [locations, setLocations] = useState<any>([]);

  useEffect(() => {
    try {
      Auth.currentAuthenticatedUser({
        bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      }).then(user => {
        setEmail(user.attributes.email);
        setUsername(user.username);
        console.log(`Load additional settings for user: ${user.username}`);
      }).catch(err => setError(err));
    }
    catch (e) {
      setError(e);
    }
    finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (joinedRooms.length == 0 && roomLoader != null && roomLoader != undefined && username != null && username != undefined) {
      connectToRoom(username, email, roomLoader.toString(), roomLoader.toString());
    }

  }, [username,email]);

  const locationReceived = (location: string) => {
    console.log(`Location Recieved in PreJoinPage: ${location}`);
    setLocations([...locations, location]);
  }


  const toggleAudio = () => {
    if (audioEnabled) {
      setAudioEnabled(false);
    } else {
      setAudioEnabled(true);
    }
  };

  const connectToRoom = async (aUsername: string, aEmail: string, aRoomName: string, carNumber: string) => {
    if (aUsername === null || aUsername.length === 0){
      console.log('Trying to connect without username');
      return;
    }
    if (
      window.location.protocol === 'https:' &&
      storedUrl.startsWith('ws://') &&
      !storedUrl.startsWith('ws://localhost')
    ) {
      alert('Unable to connect to insecure websocket from https');
      return;
    }

    console.log("Joining room: ", aRoomName);
    console.log("with Username: ", aUsername);
    console.log("with email: ", aEmail);
    const metadata = JSON.stringify({ "email": aEmail });
    const queryStringParameters =
      {
        roomName: aRoomName,
        username: aUsername,
        metadata
      };
      console.log('QueryStringParameters: ', queryStringParameters);
    let token = await API.get("liveiobackend", "/token",{queryStringParameters});

    console.log("Token: ", token);
    const params: { [key: string]: string } = {
      key: aRoomName,
      url: storedUrl,
      token,
      videoEnabled: '0',
      audioEnabled: audioEnabled ? '1' : '0',
      carNumber
    };
    if (audioDevice) {
      params.audioDeviceId = audioDevice.deviceId;
    }
    console.log("Adding Joined Room:", params)
    setJoinedRooms(prevRooms => [...prevRooms, JSON.stringify(params)]);
  };


  return (
    <>
      {loading}
      {error}


      <div className="mx-auto">
        {((showChannels) && (
          <>
            <div className="flex lg:flex-row flex-col justify-between px-10">
              <div className='flex gap-4'>
                <button className="rounded-lg text-small ring-1 px-3 py-3 bg-cyan-50 ring-cyan-600  hover:bg-cyan-100 hover:ring-2 active:bg-gray-100 active:text-pink-700"
                  key="one" onClick={() => connectToRoom(username, email,"Channel-1", "Channel 1")}>Channel 1</button>
                <button className="rounded-lg text-small ring-1 px-3 py-3 bg-cyan-50 ring-cyan-600  hover:bg-cyan-100 hover:ring-2 active:bg-gray-100 active:text-pink-700"
                  key="two" onClick={() => connectToRoom(username, email,"Channel-2", "Channel 2")}>Channel 2</button>
              </div>
              <AudioSelectButton
                className="px-3 py-3"
                isMuted={!audioEnabled}
                onClick={toggleAudio}
                onSourceSelected={setAudioDevice}
              />
              {/*
              <div className='flex gap-4'>
                <button className="rounded-sm px-5  bg-red-600  hover:bg-red-700 ring-1 " onClick={() => {EventEmitter.emit("flagPressed", "red")}}></button>
                <button className="rounded-sm px-5  bg-yellow-300 hover:bg-yellow-400 ring-1"></button>
                <button className="rounded-sm px-5  bg-green-800  hover:bg-green-900 ring-1"></button>
                <button className="rounded-sm px-5  bg-white  hover:bg-gray-200 ring-1"></button>
                <button className="rounded-sm px-5  bg-gray-400 hover:bg-gray-500 ring-1"></button>
              </div>
              */
            }
            </div>


          </>
        ))}
   <div className='pt-8 grid grid-cols-1 lg:grid-cols-12 lg:gap-4'>
            <div className='bg-gray-100 lg:col-span-4'>{joinedRooms.map((room) => { return <AudioRoom {...JSON.parse(room)} onLocationReceived={locationReceived} />; })}</div>
            <div className='bg-gray-200 lg:col-span-8 invisible lg:visible'><Map></Map></div>
          </div>

      </div>
    </>
  );
};
