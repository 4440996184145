
import { useRef, useState, useEffect } from 'react'
import EventEmitter from '../utils/EventEmitter';

export const Map = () => {

  const width = 800;
  const height = 500;
  const locationBuffer = 1000;
  const canvasRef = useRef(null);

  const zoom = 70000;


  let cities = [
    { name: "Ott", lat: 45.4215, long: -75.6972 },
   // { name: "OOS", lat: 45.392938, long: -75.6859 },

    { name: "Tor", lat: 43.6532, long: -79.3832 },
    { name: "Win", lat: 49.8954, long: -97.1385 },
    { name: "Mtl", lat: 45.5019, long: -73.5674 },
    { name: "Van", lat: 49.2827, long: -123.1207 },
    { name: "BA", lat: -34.6037, long: -58.3816 },
    { name: "Lon", lat: 51.5072, long: -0.1276 }
  ];
  const draw = (locs: any) => {
    if (center === null){
      console.log("Center not yet set.");
      return;
    }
    console.debug("Draw Map.");

    const canvas: any = canvasRef.current;
    if (canvas == null) return;
    const ctx = canvas.getContext('2d');


    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.clearRect(0, 0, width,height);
    ctx.fill();
    ctx.translate(width/2, height/2);



    if (locs.length > 0) {
      let carxy = fromLatLngToPoint({ lat: locs[0].location.lat, lng: locs[0].location.long }, center, zoom);
      ctx.fillRect(carxy.x, carxy.y, 10, 10);
    }

    const foundDrivers:string[] = [];
    const colorArray = ["#FF0000", "#00FF00", "#OOOOFF"];
    locs.forEach((loc:any) => {

      let carxy = fromLatLngToPoint({ lat: loc.location.lat, lng: loc.location.long }, center, zoom);
      if (foundDrivers.find(d=> d === loc.participant.name)) {
        ctx.fillStyle = '#333333'
        ctx.fillRect(carxy.x, carxy.y, 2, 2);
        ctx.fill();
      } else {

        foundDrivers.push(loc.participant.name);

        ctx.beginPath();
        ctx.arc(carxy.x, carxy.y, 4, 0, 2 * Math.PI, false);
        ctx.fillStyle = colorArray[foundDrivers.length];
        ctx.fill();
        ctx.lineWidth = 2;
        ctx.strokeStyle = '#003300';
        ctx.stroke();

      }
    });


    cities.forEach((city: any) => {
      ctx.fillStyle = '#000000'

      let cityxy = fromLatLngToPoint({ lat: city.lat, lng: city.long }, center, zoom);
      ctx.fillRect(cityxy.x, cityxy.y, 5, 5);

    });

      if (locs.length > locationBuffer) {
        setLocations(locs.slice(0,locationBuffer - 20));
      }
  };


  const [locations, setLocations] = useState<any[]>([]);
  const [center, setCenter] = useState<any>(null);

  useEffect(() => {

    const onLocation = (location: any) => {
      if (center === null) {
        setCenter(fromLatLngToPoint({ lat: location.location.lat, lng: location.location.long }, { x: 0, y: 0 }));
      }
      setLocations(prevLocations => ([location, ...prevLocations]));
    }
    const listener = EventEmitter.addListener('locationReceived', onLocation);
    return () => {
      listener.remove();
    }

  }, []);

  useEffect(() => {
    draw(locations);
  }, [locations]);



  function _degreesToRadians(deg: number) {
    return deg * (Math.PI / 180);
  }
  /*
  function _radiansToDegrees(rad:number) {
    return rad / (Math.PI / 180);
  }
*/
  function _bound(value: number, opt_min: number, opt_max: number) {
    if (opt_min != null) value = Math.max(value, opt_min);
    if (opt_max != null) value = Math.min(value, opt_max);
    return value;
  }

  function fromLatLngToPoint(latLng: any, center: any = { x: 0, y: 0 }, zoom: number = 1) {
    var TILE_SIZE = 800;
    var pixelOrigin_ = { x: TILE_SIZE / 2, y: TILE_SIZE / 2 };
    var pixelsPerLonDegree_ = TILE_SIZE / 360;
    var pixelsPerLonRadian_ = TILE_SIZE / (2 * Math.PI);
    var point: any = { x: null, y: null };
    var origin = pixelOrigin_;

    point.x = origin.x + latLng.lng * pixelsPerLonDegree_;

    // Truncating to 0.9999 effectively limits latitude to 89.189. This is
    // about a third of a tile past the edge of the world tile.
    var siny = _bound(Math.sin(_degreesToRadians(latLng.lat)), -0.9999, 0.9999);
    point.y = origin.y + 0.5 * Math.log((1 + siny) / (1 - siny)) * -pixelsPerLonRadian_;

    //How to transform to center.
    point.x -= center.x;
    point.y -= center.y;

    point.x *= zoom;
    point.y *= zoom;


    return point;
  };


  return (
    <> {locations.length}
      <canvas ref={canvasRef} width={width} height={height}  className=''></canvas>
    </>
  );
}

