import { Link } from '@aws-amplify/ui-react';
import { useEffect, useState } from 'react';

export const LocationRetriever = (props: any) => {

  const [location, setLocation] = useState<any>(true);
  const [shareLocation, setShareLocation] = useState<boolean>(false);

  const onLocationChanged = props.onLocationChanged;

  const enableShareLocation = () => {
    setShareLocation(true);
  }

  useEffect(() => {
    if (!shareLocation) {
      return;
    }

    let id = 0;
    if (navigator.geolocation) {
      const onPositionChanged = (result: any) => {
        console.debug("Location Changed", result)
        setLocation(result);
        onLocationChanged(result);
      };
      id = navigator.geolocation.watchPosition(onPositionChanged, (err) => { console.error(err) }, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      });
    } else {
      console.log("Location not supported")
    }
    return () => navigator.geolocation.clearWatch(id);
  }, [shareLocation]);

  if (!shareLocation) {
    return <Link onClick={() => { enableShareLocation() }}>Share Location</Link>
  }

  if (!location.coords) {
    return <div>Getting Location</div>;
  }
  return (
    <div>
      <span>{location.coords.latitude.toString()}</span>
      <span>{location.coords.longitude.toString()}</span>
    </div>
  );
};
